import { formatRate } from "~/utils/format";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";
import type { ProgramWrapper } from "~/utils/program_wrapper";
import {
  getSlice,
  hasLotsPublished,
  hasSomeRegulations,
  hasTaxZone,
} from "~/utils/helper_slice";
import { getTrimesterFromDate } from "~/utils/tools";
import type {
  Geography,
  GeographyReferenceField,
  Landing,
  Lot,
  RegulationEntity,
  SearchParams,
  TermEntityReferenceField,
} from "~/types/drupal_jsonapi";
import type {
  LotNumericField,
  LotType,
  PriceField,
  ReturnRateField,
  Settings,
} from "~/types/common";
import {
  getCheapestPrice,
  getPriceIncludingChildren,
  getPricesAndReturnRates,
  lotHasPriceBelow,
} from "./helper_lot";
import { formatPrice } from "./format";
import type { FormSystemName } from "~/types/form_system_name";
import type { BreadcrumbItem } from "~/types/breadcrumb";
import { nodeHasInPageForm } from "./helper_node";
import { urlRs } from "~/utils/url";

/** Check if the program is in preview */
export function isPreview(wrapper: ProgramWrapper): boolean {
  const settings = useDrupalSettingsContext();

  return (
    settings.sales_states_tids.avant_premiere ===
    wrapper.program.field_sales_state?.drupal_internal__tid
  );
}

/** Check if the program is configured to display manual grids */
export function isDispGridManual(wrapper: ProgramWrapper): boolean {
  return Boolean(wrapper.program.field_disp_grid_manual);
}

/** Check if the program is out of stock */
export function isOutOfStock(wrapper: ProgramWrapper) {
  const settings = useDrupalSettingsContext();
  return (
    wrapper.program.field_sales_state?.drupal_internal__tid !==
      settings.sales_states_tids.avant_premiere &&
    wrapper.lots.filter(
      (l) =>
        l.published &&
        [
          settings.lot_types.appartement,
          settings.lot_types.maison,
          settings.lot_types.terrain,
        ].includes(l.re_core_entities_lot_type.drupal_internal__tid),
    ).length === 0
  );
}

export function isLastOpportunity(wrapper: ProgramWrapper) {
  if (isPreview(wrapper)) {
    return false;
  }
  const settings = useDrupalSettingsContext();
  const lotCount = wrapper.lots.filter(
    (l) =>
      l.published &&
      [
        settings.lot_types.appartement,
        settings.lot_types.maison,
        settings.lot_types.terrain,
      ].includes(l.re_core_entities_lot_type.drupal_internal__tid),
  ).length;

  if (lotCount < 5) {
    return true;
  }

  const grid = getGrid(wrapper);

  const someTypoHasMoreThanTenLots = grid.some((typo) => typo.lots.length > 9);

  if (grid.length <= 2) {
    return !someTypoHasMoreThanTenLots;
  }
  return false;
}

/**
 * Returns the count of virtual visits including the 3D visit if it exists.
 */
export function virtualVisitsLength(wrapper: ProgramWrapper) {
  return wrapper.program.field_3d?.field_3d_url?.uri
    ? wrapper.program.field_tours.length + 1
    : wrapper.program.field_tours.length;
}

export function deliveryTrimester(wrapper: ProgramWrapper) {
  const date = getSmallestDateDelivery(wrapper);
  return date ? getTrimesterFromDate(date) : undefined;
}

export function closingDelivery(wrapper: ProgramWrapper) {
  const date = getSmallestDateClosing(wrapper);
  return date ? getTrimesterFromDate(date) : undefined;
}

export function closingDeliveryInThePast(wrapper: ProgramWrapper) {
  const date = getSmallestDateClosing(wrapper);
  return date ? new Date(date) < new Date() : false;
}

export function closingDeliveryIsBeforeDate_31_12_2023(
  wrapper: ProgramWrapper,
) {
  const closing = getSmallestDateClosing(wrapper);
  const date = new Date("2023-12-31");
  return closing ? new Date(closing) < date : false;
}

export function getSmallestDateDelivery(
  wrapper: ProgramWrapper,
): string | undefined {
  const slices = wrapper.slices.filter((slice) =>
    hasLotsPublished(slice, wrapper),
  );

  const dates = slices.map((slice) => slice.date_delivery).sort();
  return dates.at(0);
}

export function isDateDisplayPricesPast(wrapper: ProgramWrapper) {
  const date = wrapper.program.field_date_display_prices;
  return date ? new Date(date) < new Date() : false;
}

export function getSmallestDateClosing(
  wrapper: ProgramWrapper,
): string | undefined {
  const slices = wrapper.slices.filter((slice) =>
    hasLotsPublished(slice, wrapper),
  );

  const dates = slices.map((slice) => slice.date_closing).sort();
  return dates.at(0);
}

export function hasBareOwnershipSlice(wrapper: ProgramWrapper) {
  const settings = useDrupalSettingsContext();

  const fnFilterRegulation = (reg: TermEntityReferenceField) => {
    return reg.drupal_internal__tid === settings.regulations_tids.nue_propriete;
  };

  const slices = wrapper.slices.filter(
    (slice) => slice.regulation.filter(fnFilterRegulation).length > 0,
  );

  return slices.length > 0;
}

export function hasLMNPVatExSlice(wrapper: ProgramWrapper) {
  const settings = useDrupalSettingsContext();

  const fnFilterRegulation = (reg: TermEntityReferenceField) => {
    return reg.drupal_internal__tid === settings.regulations_tids.lmnp_vat_ex;
  };

  const slices = wrapper.slices.filter(
    (slice) => slice.regulation.filter(fnFilterRegulation).length > 0,
  );

  return slices.length > 0;
}

export function isPrixEncadresOverride(wrapper: ProgramWrapper) {
  const settings = useDrupalSettingsContext();
  return (
    wrapper.program.drupal_internal__nid === settings.prix_encadres_override
  );
}

/** Returns zones names as a string comma separated: A, A bis */
export function getPinelZones(wrapper: ProgramWrapper) {
  const settings = useDrupalSettingsContext();
  const pinel_tid = settings.regulations_tids.pinel;
  const zones = new Set<string>();

  const fnFilterRegulation = (reg: TermEntityReferenceField) => {
    return reg.drupal_internal__tid === pinel_tid;
  };

  const slicesWithPinel = getSlicesWithAtLeastOneLot(wrapper).filter(
    (slice) =>
      slice.regulation.filter(fnFilterRegulation).length > 0 &&
      hasTaxZone(slice),
  );

  slicesWithPinel.forEach((slice) => {
    zones.add(slice.tax_zone.name);
  });

  return Array.from(zones).sort().join(", ");
}

/** Returns zones names as a string comma separated: A, A bis */
export function getPinelPlusZones(wrapper: ProgramWrapper) {
  const settings = useDrupalSettingsContext();
  const pinel_plus_tid = settings.regulations_tids.pinel_plus;
  const zones = new Set<string>();

  const fnFilterRegulation = (reg: TermEntityReferenceField) => {
    return reg.drupal_internal__tid === pinel_plus_tid;
  };

  const slicesWithPinel = getSlicesWithAtLeastOneLot(wrapper).filter(
    (slice) =>
      slice.regulation.filter(fnFilterRegulation).length > 0 &&
      hasTaxZone(slice),
  );

  slicesWithPinel.forEach((slice) => {
    zones.add(slice.tax_zone.name);
  });

  return Array.from(zones).sort().join(", ");
}

/** Returns zones names as a string comma separated: A, A bis */
export function getPinelAndPinelPlusZones(wrapper: ProgramWrapper) {
  const settings = useDrupalSettingsContext();
  const pinel_tid = settings.regulations_tids.pinel;
  const pinel_plus_tid = settings.regulations_tids.pinel_plus;
  const zones = new Set<string>();

  const fnFilterRegulation = (reg: TermEntityReferenceField) => {
    return (
      reg.drupal_internal__tid === pinel_tid ||
      reg.drupal_internal__tid === pinel_plus_tid
    );
  };

  const slicesWithPinel = getSlicesWithAtLeastOneLot(wrapper).filter(
    (slice) =>
      slice.regulation.filter(fnFilterRegulation).length > 0 &&
      hasTaxZone(slice),
  );

  slicesWithPinel.forEach((slice) => {
    zones.add(slice.tax_zone.name);
  });

  return Array.from(zones).sort().join(", ");
}

export function getSlicesWithAtLeastOneLot(wrapper: ProgramWrapper) {
  const publishedLots = wrapper.lots.filter((lot) => lot.published);
  return wrapper.slices.filter(
    (slice) =>
      publishedLots.filter(
        (lot) =>
          lot.slice_id.meta.drupal_internal__target_id ===
          slice.drupal_internal__id,
      ).length > 0,
  );
}

export type GridTypology = {
  typology: TermEntityReferenceField;
  getBiggestSurface: () => number;
  getCheapestPrice: () => number | undefined;
  getCurrentStock: () => number;
  isLowInStock: () => boolean;
  hasVirtualVisit: () => boolean;
  getVirtualVisitUrl: () => string | undefined;
  lots: Lot[];
};

type GridData = GridTypology[];

/**
 * Returns the data structure for the grid.
 *
 * Output is an array of typologies, each containing an array of lots.
 *
 * @param wrapper
 * @param searchParams
 */
export function getGrid(
  wrapper: ProgramWrapper,
  searchParams?: SearchParams,
): GridData {
  const settings = useDrupalSettingsContext();

  const dispGridsEnabled = getEnabledDispGrids(wrapper);
  const regulationsTidsEnabled = dispGridsEnabled.map((fieldName) =>
    getRegulationTidForDispGrid(fieldName),
  );

  let searchedPriceFields: PriceField[] = [];

  const regulations =
    searchParams?.regulations ?? settings.regulations.map((r) => r.name);

  regulations.forEach((regulation) => {
    switch (regulation) {
      case "TVA normale":
      case "Pinel":
      case "Pinel +":
      case "Patrimonial":
      case "LMNP":
        searchedPriceFields.push("price_vat_inc");
        break;

      case "TVA réduite":
        searchedPriceFields.push("price_vat_inc_reduced");
        break;
      case "Nue-propriété":
        if (
          hasGridWithRegulation(
            wrapper,
            settings.regulations_tids.nue_propriete,
          )
        ) {
          searchedPriceFields.push("price_vat_inc_reduced");
        }
        break;

      case "Prix BRS":
        searchedPriceFields.push("price_vat_inc_brs");
        break;

      case "Prix maîtrisés":
        searchedPriceFields.push("price_vat_inc_mastered");
        break;

      case "LMNP géré":
        if (
          hasGridWithRegulation(wrapper, settings.regulations_tids.lmnp_vat_ex)
        ) {
          searchedPriceFields.push("price_vat_ex");
        }
        break;
    }
  });

  // Unique price fields
  searchedPriceFields = Array.from(new Set(searchedPriceFields));

  // Only use slices with matched regulations
  const slicesIds = wrapper.slices
    // Filter slices matching enabled grids
    .filter((s) =>
      s.regulation.some((r) =>
        regulationsTidsEnabled.includes(r.drupal_internal__tid),
      ),
    )
    // Filter slices matching searchParams regulations or all if no regulations
    .filter((s) => {
      if (searchParams?.regulations?.length) {
        return s.regulation.some((r) =>
          (searchParams?.regulations as string[]).includes(r.name),
        );
      }
      return true;
    })
    .map((s) => s.drupal_internal__id);

  // Gets published lots from selected slices excluding parkings, caves and cellars.
  const lots = wrapper.lots
    .filter(
      (lot) =>
        slicesIds.includes(lot.slice_id.meta.drupal_internal__target_id) &&
        lot.published &&
        lot.re_core_entities_lot_type.drupal_internal__tid !==
          settings.lot_types.parking &&
        lot.re_core_entities_lot_type.drupal_internal__tid !==
          settings.lot_types.cellier &&
        lot.re_core_entities_lot_type.drupal_internal__tid !==
          settings.lot_types.cave,
    ) // Pinel but NOT Pinel +
    .filter((lot) => {
      if (searchParams?.regulations) {
        return searchParams?.regulations?.includes("Pinel") &&
          !searchParams?.regulations?.includes("Pinel +")
          ? lot.is_pinel_plus === false
          : true;
      }
      return true;
    })
    // NOT Pinel but Pinel +
    .filter((lot) => {
      if (searchParams?.regulations) {
        return !searchParams?.regulations?.includes("Pinel") &&
          searchParams?.regulations?.includes("Pinel +")
          ? lot.is_pinel_plus === true
          : true;
      }
      return true;
    })
    // Rooms
    .filter((lot) => {
      if (searchParams?.rooms) {
        const nums = searchParams.rooms.map((r) => Number(r));
        if (nums.includes(5)) {
          return lot.rooms && lot.rooms >= 5;
        }
        return lot.rooms && nums.includes(lot.rooms);
      }
      return true;
    })
    // Type
    .filter((lot) => {
      if (searchParams?.lot_types) {
        return searchParams.lot_types.includes(
          lot.re_core_entities_lot_type.name as LotType,
        );
      }
      return true;
    })
    // Budget
    .filter((lot) => {
      // Budget
      if (searchParams?.budget && searchParams?.budget < 900_000) {
        return lotHasPriceBelow(
          lot,
          searchParams.budget,
          searchedPriceFields,
          wrapper,
        );
      }
      return true;
    });

  // Get typologies from each lot to create a set of unique typologies
  const allTypos: TermEntityReferenceField[] = [];
  const typoIdsAdded: number[] = [];

  lots.forEach((lot) => {
    if (typoIdsAdded.includes(lot.typology.drupal_internal__tid)) {
      return;
    }

    allTypos.push(lot.typology);
    typoIdsAdded.push(lot.typology.drupal_internal__tid);
  });

  const typologies = allTypos.sort(sortByWeightAndNameFn);

  const fields = getPricesFieldNamesForGrids(getEnabledDispGrids(wrapper));

  return typologies.map((typology) => {
    const typoLots = lots
      .filter(
        (lot) =>
          lot.typology.drupal_internal__tid === typology.drupal_internal__tid,
      )
      .sort(createSortLotsFn(fields));

    const getBiggestSurface = () => {
      return typoLots.reduce((acc, lot) => {
        return Number(lot.surface) > acc ? Number(lot.surface) : acc;
      }, 0);
    };

    const getCheapestPrice = () => {
      const min = typoLots.reduce((acc, lot) => {
        const pricesAndReturnRate = getPricesAndReturnRates(wrapper, lot);

        const prices = Object.values(pricesAndReturnRate)
          .map((group) => (group ? group.price_raw : undefined))
          .filter(Boolean);

        const small = Math.min(...(prices as number[]).filter(Boolean));
        if (small === Number.POSITIVE_INFINITY) {
          return acc;
        }

        const min = Number(small);

        return min < acc || acc === 0 ? min : acc;
      }, 0);

      return min > 0 ? min : undefined;
    };

    const getCurrentStock = () => typoLots.length;
    const isLowInStock = () => getCurrentStock() < 3;

    const getVirtualVisitUrl = () => {
      const tour = wrapper.program.field_tours.find((tour) => {
        if (!tour.field_tour_typology) {
          return false;
        }

        return tour.field_tour_typology.find(
          (t) => t.drupal_internal__tid === typology.drupal_internal__tid,
        );
      });

      return tour ? tour.field_tour_url.uri : undefined;
    };

    const hasVirtualVisit = () => {
      return !!getVirtualVisitUrl();
    };

    return {
      typology,
      getBiggestSurface,
      getCheapestPrice,
      getCurrentStock,
      isLowInStock,
      hasVirtualVisit,
      getVirtualVisitUrl,
      lots: typoLots,
    };
  });
}

const dispGridFieldNames = [
  "field_disp_grid_bare_ownership",
  "field_disp_grid_lmnp_vat_ex",
  "field_disp_grid_lmnp_vat_inc",
  "field_disp_grid_patrimonial",
  "field_disp_grid_pinel",
  "field_disp_grid_pinel_plus",
  "field_disp_grid_vat_inc",
  "field_disp_grid_vat_inc_brs",
  "field_disp_grid_vat_inc_mastered",
  "field_disp_grid_vat_inc_reduced",
] as const;

type DispGridFieldName = (typeof dispGridFieldNames)[number];

export function getRegulationTidForDispGrid(field: DispGridFieldName): number {
  const mapping: Record<DispGridFieldName, keyof Settings["regulations_tids"]> =
    {
      field_disp_grid_bare_ownership: "nue_propriete",
      field_disp_grid_lmnp_vat_ex: "lmnp_vat_ex",
      field_disp_grid_lmnp_vat_inc: "lmnp_vat_inc",
      field_disp_grid_patrimonial: "patrimonial",
      field_disp_grid_pinel: "pinel",
      field_disp_grid_pinel_plus: "pinel_plus",
      field_disp_grid_vat_inc: "tva_normale",
      field_disp_grid_vat_inc_brs: "brs",
      field_disp_grid_vat_inc_mastered: "prix_maitrises",
      field_disp_grid_vat_inc_reduced: "tva_reduite",
    };

  const settings = useDrupalSettingsContext();
  return settings.regulations_tids[mapping[field]];
}

export function hasGridWithRegulation(
  wrapper: ProgramWrapper,
  regulationTid: number,
) {
  const field = dispGridFieldNames.find(
    (f) => getRegulationTidForDispGrid(f) === regulationTid,
  );

  return field ? Boolean(wrapper.program[field]) : false;
}

export function getEnabledDispGrids(wrapper: ProgramWrapper) {
  return dispGridFieldNames.filter((f) => wrapper.program[f]);
}

export function arePricesDisplayed(wrapper: ProgramWrapper) {
  return (
    getEnabledDispGrids(wrapper).length > 0 && isDateDisplayPricesPast(wrapper)
  );
}

/**
 * Returns an array of unique regulations for the program.
 *
 * @param wrapper ProgramWrapper
 * @returns An array of unique regulations
 */
export function getRegulations(wrapper: ProgramWrapper) {
  const regulations = wrapper.slices.map((slice) => slice.regulation);

  function dedupeArrayWithMap(array: RegulationEntity[]) {
    const seen = new Map();
    array.forEach((item) => {
      if (!seen.has(item.drupal_internal__tid)) {
        seen.set(item.drupal_internal__tid, item);
      }
    });
    return Array.from(seen.values());
  }

  const unique = dedupeArrayWithMap(regulations.flat());

  return unique.sort(sortByWeightAndNameFn);
}

/**
 * Returns the price field names for the grids.
 * @param gridNames Grids names
 * @returns PriceFieldName[]
 */
export function getPricesFieldNamesForGrids(
  gridNames: DispGridFieldName[],
): PriceField[] {
  const settings = useDrupalSettingsContext();
  const fields = gridNames.map((gridName) => {
    const regulationTid = getRegulationTidForDispGrid(gridName);
    switch (regulationTid) {
      case settings.regulations_tids.tva_normale:
      case settings.regulations_tids.pinel:
      case settings.regulations_tids.pinel_plus:
      case settings.regulations_tids.patrimonial:
      case settings.regulations_tids.lmnp_vat_inc:
      default:
        return "price_vat_inc";
      case settings.regulations_tids.tva_reduite:
      case settings.regulations_tids.nue_propriete:
        return "price_vat_inc_reduced";
      case settings.regulations_tids.prix_maitrises:
        return "price_vat_inc_mastered";
      case settings.regulations_tids.brs:
        return "price_vat_inc_brs";
      case settings.regulations_tids.lmnp_vat_ex:
        return "price_vat_ex";
    }
  });

  // Unique
  return [...new Set(fields)];
}

/**
 * Sorts typologies by weight and name.
 *
 * @param a EntityWithWeight
 * @param b EntityWithWeight
 * @returns number
 */

type EntityWithWeight = {
  weight: number;
  name: string;
};

export function sortByWeightAndNameFn(
  a: EntityWithWeight,
  b: EntityWithWeight,
) {
  return a.weight === b.weight
    ? a.name.localeCompare(b.name)
    : a.weight > b.weight
      ? 1
      : -1;
}

export function createSortLotsFn(priceFields: PriceField[]) {
  return function (a: Lot, b: Lot) {
    const aFloorFirstChar = a.floor ? a.floor.charAt(0) : "";
    const bFloorFirstChar = b.floor ? b.floor.charAt(0) : "";

    // If aFloorFirstChar is a number, and bFloorFirstChar is not a number
    if (aFloorFirstChar.match(/\d/) && !bFloorFirstChar.match(/\d/)) {
      return 1;
    }

    if (!aFloorFirstChar.match(/\d/) && bFloorFirstChar.match(/\d/)) {
      return -1;
    }

    // Compare floors like PHP strnatcomp
    const natSort = aFloorFirstChar.localeCompare(bFloorFirstChar, undefined, {
      numeric: true,
      sensitivity: "base",
    });

    if (natSort < 0 || natSort > 0) {
      return natSort;
    }

    if (getCheapestPrice(a, priceFields) > getCheapestPrice(b, priceFields)) {
      return 1;
    } else if (
      getCheapestPrice(a, priceFields) < getCheapestPrice(b, priceFields)
    ) {
      return -1;
    }
    return 0;
  };
}

/**
 * Returns true if the program has at least one VAT inc price based grid.
 *
 * @param wrapper ProgramWrapper
 * @returns boolean
 */
export function hasSomeVatIncGrid(wrapper: ProgramWrapper) {
  const fields = [
    "field_disp_grid_vat_inc",
    "field_disp_grid_patrimonial",
    "field_disp_grid_pinel",
    "field_disp_grid_pinel_plus",
    "field_disp_grid_lmnp_vat_inc",
  ] as const;

  return fields.some((f) => wrapper.program[f]);
}

/**
 * Builds a summary of the typologies for the program.
 * @param wrapper ProgramWrapper
 * @returns string | undefined
 */
export function getTypologiesSummary(wrapper: ProgramWrapper) {
  const settings = useDrupalSettingsContext();
  const grid = getGrid(wrapper);

  if (grid.length === 0) {
    return undefined;
  }

  // Terrains only
  if (
    grid.length === 1 &&
    wrapper.program.field_program_type?.drupal_internal__tid ===
      settings.program_types.terrains
  ) {
    return "Terrains";
  }

  // One typology
  if (grid.length === 1) {
    const firstTypo = grid[0];
    if (firstTypo.lots.length > 0) {
      const label = firstTypo.lots[0].typology.name.toLowerCase();
      // label starts with a number?
      if (/^\d/.test(label)) {
        const type = firstTypo.lots[0].re_core_entities_lot_type.name;
        const out = `${type} ${label}`;
        return out.charAt(0).toUpperCase() + out.slice(1);
      } else {
        return label.charAt(0).toUpperCase() + label.slice(1);
      }
    }

    return undefined;
  }

  // Multiple typologies
  if (grid.length > 1) {
    const firstTypo = grid[0];
    const smallestLot = firstTypo.lots[0];
    const smallPrefix = smallestLot.typology.field_prefix_1;
    const smallTypology = smallestLot.typology.name;

    const lastTypo = grid.at(grid.length - 1)!;
    const biggestLot = lastTypo.lots.at(lastTypo.lots.length - 1)!;
    const bigPrefix = biggestLot.typology.field_prefix_2;
    const bigTypology = biggestLot.typology.name;

    let out = `${smallPrefix}<strong>${smallTypology}</strong> ${bigPrefix}<strong>${bigTypology}</strong>`;
    out = out.toLocaleLowerCase();
    return out.charAt(0).toUpperCase() + out.slice(1);
  }

  return undefined;
}

/**
 * Builds a summary of the prices for the program.
 * @param wrapper ProgramWrapper
 * @returns string | undefined
 */
export function getPricesSummary(wrapper: ProgramWrapper) {
  const nous_consulter = "Prix nous consulter";
  if (!isDateDisplayPricesPast(wrapper)) {
    return nous_consulter;
  }

  const fields = getPricesFieldNamesForGrids(getEnabledDispGrids(wrapper));

  const lots = getGrid(wrapper)
    .map((typo) => typo.lots)
    .flat();

  const prices = lots.map((lot) => {
    return fields.map((field) => {
      return getPriceIncludingChildren(lot, field, wrapper);
    });
  });

  const pricesFlat = prices.flat().filter(Boolean);

  if (pricesFlat.length === 0) {
    return nous_consulter;
  }

  const smallestPrice = Math.min(...pricesFlat);

  if (smallestPrice === 0) {
    return nous_consulter;
  }

  const price = formatPrice(smallestPrice);

  return `<small>à partir de</small> <span itemprop="priceRange">${price}</span>`;
}

export function isOnlyInvest(wrapper: ProgramWrapper) {
  const settings = useDrupalSettingsContext();

  if (
    isPreview(wrapper) &&
    (hasGridWithRegulation(wrapper, settings.regulations_tids.lmnp_vat_ex) ||
      hasGridWithRegulation(wrapper, settings.regulations_tids.lmnp_vat_inc) ||
      hasGridWithRegulation(wrapper, settings.regulations_tids.nue_propriete) ||
      hasGridWithRegulation(wrapper, settings.regulations_tids.patrimonial))
  ) {
    return true;
  }

  const regulations = [
    ...new Set(
      wrapper.slices
        .map((slice) => slice.regulation.map((r) => r.drupal_internal__tid))
        .flat(),
    ),
  ];

  if (regulations.length > 0) {
    const dwell = regulations.find(
      (reg) =>
        reg === settings.regulations_tids.tva_normale ||
        reg === settings.regulations_tids.tva_reduite ||
        reg === settings.regulations_tids.prix_maitrises ||
        reg === settings.regulations_tids.brs,
    );

    if (dwell === undefined) {
      return true;
    }
  }
  return false;
}

export function getFirstInvestRegulationName(
  wrapper: ProgramWrapper,
): string | undefined {
  const settings = useDrupalSettingsContext();

  for (const slice of wrapper.slices) {
    const reg = slice.regulation.find(
      (r) =>
        r.drupal_internal__tid === settings.regulations_tids.pinel ||
        r.drupal_internal__tid === settings.regulations_tids.pinel_plus ||
        r.drupal_internal__tid === settings.regulations_tids.lmnp_vat_ex ||
        r.drupal_internal__tid === settings.regulations_tids.lmnp_vat_inc ||
        r.drupal_internal__tid === settings.regulations_tids.nue_propriete ||
        r.drupal_internal__tid === settings.regulations_tids.patrimonial,
    );

    if (reg) {
      return reg.name;
    }
  }
}

export function getBestReturnRate(
  wrapper: ProgramWrapper,
  regulationTids: number[],
) {
  const settings = useDrupalSettingsContext();

  // Pinel
  if (regulationTids.includes(settings.regulations_tids.pinel)) {
    return getBestReturnRateForField(wrapper, "return_rate_pinel", [
      settings.regulations_tids.pinel,
    ]);
  }

  // Pinel+
  if (regulationTids.includes(settings.regulations_tids.pinel_plus)) {
    return getBestReturnRateForField(wrapper, "return_rate_pinel", [
      settings.regulations_tids.pinel_plus,
    ]);
  }

  // Pinel & Pinel+
  if (
    regulationTids.includes(settings.regulations_tids.pinel) &&
    regulationTids.includes(settings.regulations_tids.pinel_plus)
  ) {
    return getBestReturnRateForField(wrapper, "return_rate_pinel", [
      settings.regulations_tids.pinel,
      settings.regulations_tids.pinel_plus,
    ]);
  }

  // LMNP VAT inc
  if (regulationTids.includes(settings.regulations_tids.lmnp_vat_inc)) {
    return getBestReturnRateForField(wrapper, "return_rate_vat_inc", [
      settings.regulations_tids.lmnp_vat_inc,
    ]);
  }

  // LMNP VAT ex
  if (regulationTids.includes(settings.regulations_tids.lmnp_vat_ex)) {
    return getBestReturnRateForField(wrapper, "return_rate_vat_ex", [
      settings.regulations_tids.lmnp_vat_ex,
    ]);
  }

  return undefined;
}

export function hasSliceWithRegulation(
  wrapper: ProgramWrapper,
  regulationTid: number,
) {
  return wrapper.slices.some((slice) =>
    slice.regulation.some((reg) => reg.drupal_internal__tid === regulationTid),
  );
}

export function hasSliceWithSomeRegulation(
  wrapper: ProgramWrapper,
  regulationTids: number[],
) {
  return wrapper.slices.some((slice) =>
    slice.regulation.some((reg) =>
      regulationTids.includes(reg.drupal_internal__tid),
    ),
  );
}

export function getBestReturnRateForField(
  wrapper: ProgramWrapper,
  field: ReturnRateField,
  regulationTids: number[],
) {
  const values = wrapper.lots
    .filter(Boolean)
    .filter((l) => {
      const slice = getSlice(
        l.slice_id.meta.drupal_internal__target_id,
        wrapper,
      );
      // We lay have some caching race condition, so ensure slice is not undefined
      if (slice && hasSomeRegulations(slice!, regulationTids)) {
        return l.published;
      }
      return false;
    })
    .map((l) => Number(l[field]))
    .filter(Boolean);

  const max = Math.max(...values);

  if (max > 0) {
    return formatRate(max);
  }

  return undefined;
}

export function getSmallestNumericField(
  wrapper: ProgramWrapper,
  field: LotNumericField,
) {
  const values = wrapper.lots
    .filter((l) => l.published)
    .map((l) => Number(l[field]))
    .filter(Boolean);
  return Math.min(...values);
}

export function getSmallestVatRate(wrapper: ProgramWrapper) {
  const rate = getSmallestNumericField(wrapper, "vat_rate");
  return rate > 0 ? formatRate(rate) : undefined;
}

export function shouldHideDestination(
  entity: ProgramWrapper | Landing | undefined,
  form_name: FormSystemName,
) {
  if (!entity) {
    return false;
  }

  const settings = useDrupalSettingsContext();

  const formsWhereDestinationCouldBeHidden: FormSystemName[] = [
    "callback",
    "advisor",
    "leaflet",
    "blueprint",
    "in-page",
  ];

  // This is a program
  if ("program" in entity) {
    const bare_ownership = hasGridWithRegulation(
      entity,
      settings.regulations_tids.nue_propriete,
    );
    const lmnp_vat_ex =
      hasGridWithRegulation(entity, settings.regulations_tids.lmnp_vat_ex) &&
      Number(entity.program.field_dwelling_type?.length) > 0;

    return (
      formsWhereDestinationCouldBeHidden.includes(form_name) &&
      (bare_ownership || lmnp_vat_ex)
    );
  }

  // This is a landing
  if ("type" in entity && entity.type === "node--landing") {
    return entity.field_type === "investir";
  }

  return false;
}

export function shouldShowDestination(
  entity: ProgramWrapper | Landing | undefined,
  form_name: FormSystemName,
) {
  return !shouldHideDestination(entity, form_name);
}

export function geographyBreadcrumbItems(
  geography: GeographyReferenceField,
  geographies: BreadcrumbItem[],
) {
  geographies.push({
    href: urlRs("geographies", geography.path!.alias),
    text: geography.name,
  });

  if (
    geography.parent &&
    geography.parent.length > 0 &&
    geography.parent[0].name &&
    geography.parent[0].path?.alias
  ) {
    geographyBreadcrumbItems(geography.parent![0], geographies);
  }

  return geographies;
}

export function geographyBreadcrumbItemsForGeography(
  tid: number,
  geography: Geography,
  geographies: BreadcrumbItem[],
) {
  if (geography.drupal_internal__tid !== tid) {
    geographies.push({
      href: urlRs("geographies", geography.path!.alias),
      text: geography.name,
    });
  }

  if (
    geography.parent &&
    geography.parent.length > 0 &&
    geography.parent[0].name &&
    geography.parent[0].path?.alias
  ) {
    geographyBreadcrumbItemsForGeography(
      tid,
      geography.parent![0],
      geographies,
    );
  }

  return geographies;
}

export function shouldShowInPageForm(wrapper: ProgramWrapper) {
  return isPreview(wrapper) && nodeHasInPageForm(wrapper.program);
}

export function getParkingsByTypologies(wrapper: ProgramWrapper) {
  const settings = useDrupalSettingsContext();

  const lots = wrapper.lots.filter((lot) => {
    return (
      lot.re_core_entities_lot_type.drupal_internal__tid ===
        settings.lot_types.parking &&
      lot.published &&
      lot.lot_parent_id === null &&
      lot.parent_id === null
    );
  });

  // Get typologies from each lot to create a set of unique typologies
  const allTypos: TermEntityReferenceField[] = [];
  const typoIdsAdded: number[] = [];

  lots.forEach((lot) => {
    if (typoIdsAdded.includes(lot.typology.drupal_internal__tid)) {
      return;
    }

    allTypos.push(lot.typology);
    typoIdsAdded.push(lot.typology.drupal_internal__tid);
  });

  const typologies = allTypos.sort(sortByWeightAndNameFn);

  return typologies.map((typology) => {
    const typoLots = lots.filter(
      (lot) =>
        lot.typology.drupal_internal__tid === typology.drupal_internal__tid,
    );

    const getCheapestPrice = () => {
      const min = typoLots.reduce((acc, lot) => {
        const pricesAndReturnRate = getPricesAndReturnRates(wrapper, lot);

        const prices = Object.values(pricesAndReturnRate)
          .map((group) => (group ? group.price_raw : undefined))
          .filter(Boolean);

        const small = Math.min(...(prices as number[]).filter(Boolean));
        if (small === Number.POSITIVE_INFINITY) {
          return acc;
        }

        const min = Number(small);

        return min < acc || acc === 0 ? min : acc;
      }, 0);

      return min > 0 ? min : undefined;
    };

    const getFirstLotWithPrice = () => {
      return typoLots.find((lot) => {
        return (
          lot.price_vat_ex || lot.price_vat_inc || lot.price_vat_inc_reduced
        );
      });
    };

    return {
      typology,
      lots: typoLots,
      getCheapestPrice,
      getFirstLotWithPrice,
    };
  });
}
