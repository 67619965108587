import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";
import type { PriceField } from "~/types/common";
import type { Slice, TermEntityReferenceField } from "~/types/drupal_jsonapi";
import type { ProgramWrapper } from "~/utils/program_wrapper";

export function isArchived(slice: Slice) {
  return slice.archived;
}

export function lastUpdateWasBefore(slice: Slice, date: Date) {
  return new Date(slice.changed) < date;
}

/**
 * Check if the slice has published lots.
 *
 * @param slice the slice to check
 * @param wrapper the program wrapper
 * @returns weather the slice has published lots
 */
export function hasLotsPublished(slice: Slice, wrapper: ProgramWrapper) {
  const lots = wrapper.lots
    .filter((lot) => {
      return (
        lot.slice_id.meta.drupal_internal__target_id ===
        slice.drupal_internal__id
      );
    })
    .filter((lot) => lot.published);

  return lots.length > 0;
}

export function hasTaxZone(slice: Slice) {
  return slice.tax_zone && slice.tax_zone.name;
}

export function hasSomeRegulations(
  slice: Slice,
  regulation_tids: number[] = [],
) {
  const fnFilterRegulation = (reg: TermEntityReferenceField) => {
    return regulation_tids.includes(reg.drupal_internal__tid);
  };

  return slice.regulation.some(fnFilterRegulation);
}

export function getSlice(slice_id: number, wrapper: ProgramWrapper) {
  return wrapper.slices.find((slice) => slice.drupal_internal__id === slice_id);
}

export function hasRegulation(slice: Slice, regulation_tid: number) {
  return slice.regulation.some(
    (reg) => reg.drupal_internal__tid === regulation_tid,
  );
}

export function getRegulation(slice: Slice, regulation_tid: number) {
  return slice.regulation.find(
    (reg) => reg.drupal_internal__tid === regulation_tid,
  );
}

/**
 * Returns the price field names for the slice's regulations.
 * @param Slice slice
 * @returns PriceFieldName[]
 */
export function getPricesFieldNamesForRegulationTids(
  slice: Slice,
): PriceField[] {
  const settings = useDrupalSettingsContext();
  const fields = slice.regulation.map((regulation) => {
    switch (regulation.drupal_internal__tid) {
      case settings.regulations_tids.tva_normale:
      case settings.regulations_tids.pinel:
      case settings.regulations_tids.pinel_plus:
      case settings.regulations_tids.patrimonial:
      case settings.regulations_tids.lmnp_vat_inc:
      default:
        return "price_vat_inc";
      case settings.regulations_tids.tva_reduite:
      case settings.regulations_tids.nue_propriete:
        return "price_vat_inc_reduced";
      case settings.regulations_tids.prix_maitrises:
        return "price_vat_inc_mastered";
      case settings.regulations_tids.brs:
        return "price_vat_inc_brs";
      case settings.regulations_tids.lmnp_vat_ex:
        return "price_vat_ex";
    }
  });

  // Unique
  return [...new Set(fields)];
}
